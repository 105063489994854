<template class="mr-3">
  <div class="card">
    <a-modal
      v-model="visibleModal"
      @cancel="closeModal"
      :title="isPdfModal ? $t('action.imprimerTab') : 'Export CSV'"
      :width="500"
      :height="1200"
      :footer="false"
    >
      <div class="mb-3">
        <span>{{ $t("all.filterBy") }}:</span>
        <a-select
          id="selectMonth"
          :placeholder="$t('recette.tousMois')"
          style="width: 200px; margin-left: 15px"
          option-filter-prop="children"
          show-search
          :filter-option="filterOption"
          @change="handleChangeFilter"
          :value="selectedMonth"
        >
          <a-select-option value="all">
            {{ $t("recette.tousMois") }}
          </a-select-option>
          <a-select-option
            v-for="i in [
              '01',
              '02',
              '03',
              '04',
              '05',
              '06',
              '07',
              '08',
              '09',
              '10',
              '11',
              '12',
            ]"
            :key="i"
            :value="i"
          >
            {{ moment.months(Number(i) - 1) }}
          </a-select-option>
        </a-select>
      </div>

      <div class="form-actions mt-0">
        <a-button
          v-if="isPdfModal"
          type="primary"
          :loading="loadingPdf"
          :disabled="loadingPdf"
          @click="generatePdf"
          class="mr-3"
        >
          {{ $t("action.imprimer") }}
        </a-button>
        <a-button
          v-else
          class="mr-3"
          :loading="loadingCSV"
          :disabled="loadingCSV"
          type="primary"
          @click="generateCSV"
        >
          <span>Export</span>
        </a-button>
        <a-button key="back" @click="closeModal">
          {{ $t("action.annuler") }}
        </a-button>
      </div>
    </a-modal>
    <div class="card-body">
      <div class="mb-2">
        &nbsp;
        <a-button
          icon="file-pdf"
          :loading="loadingPdf"
          :disabled="loadingPdf"
          @click="
            () => {
              visibleModal = true;
              isPdfModal = true;
            }
          "
          style="margin-bottom: 20px; float: right"
        >
          {{ $t("action.imprimerTab") }}
        </a-button>
        <a-button
          class="mr-2"
          :loading="loadingCSV"
          :disabled="loadingCSV"
          @click="
            () => {
              visibleModal = true;
              isPdfModal = false;
            }
          "
          style="margin-bottom: 20px; float: right"
        >
          <a-icon style="color: green" type="file-excel" /><span>CSV</span>
        </a-button>
      </div>
      <div>
        <a-table
          @change="handleTableChange"
          :loading="tableLoading"
          rowKey="_id"
          :pagination="pagination"
          :data-source="data"
          :columns="columns"
        >
          <template slot="expandedRowRender" slot-scope="record">
            <div style="margin: 0">
              <table class="table table-striped">
                <thead>
                  <th>{{ $t("recette.mois") }}</th>
                  <th>{{ $t("recette.montant") }}</th>
                  <th>{{ $t("topBar.status") }}</th>
                </thead>
                <tbody>
                  <tr v-if="record.dataType.inscription && mF">
                    <td>{{ $t("recette.inscription") }}</td>
                    <td v-if="!record.inscription">
                      <del v-if="record.repReduction.inscription > 0">
                        {{ record.dataType.inscription }}
                      </del>
                      <div v-else>
                        {{ record.dataType.inscription }}
                      </div>
                      <sup
                        v-if="record.repReduction.inscription > 0"
                        class="text-success"
                        >-{{ record.repReduction.inscription }}%</sup
                      >
                      <div v-if="record.repReduction.inscription > 0">
                        ={{ record.monthsMoney.inscription }}
                      </div>
                    </td>
                    <td v-else>
                      <div>{{ record.monthsMoney.inscription }}</div>
                    </td>
                    <td>
                      {{
                        record.inscription
                          ? $t("paiement.paye")
                          : $t("paiement.impaye")
                      }}
                    </td>
                  </tr>
                  <tr v-for="(month, key) in record.months" :key="key">
                    <td>{{ getMonthName(key) }} ({{ key }})</td>
                    <td>
                      <div v-if="!record.months[key]">
                        <del v-if="record.repReduction[key] > 0">
                          {{ record.dataType.monthly }}
                        </del>
                        <div v-else>{{ record.dataType.monthly }}</div>
                        <sup
                          v-if="record.repReduction[key] > 0"
                          class="text-success"
                          >-{{ record.repReduction[key] }}%</sup
                        >
                        <div v-if="record.repReduction[key] > 0">
                          ={{ record.monthsMoney[key] }}
                        </div>
                      </div>
                      <div v-else>
                        <div>{{ record.monthsMoney[key] }}</div>
                      </div>
                    </td>
                    <td>
                      {{
                        record.months[key]
                          ? $t("paiement.paye")
                          : $t("paiement.impaye")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template slot="status" slot-scope="text">
            {{ text == "payed" ? $t("all.oui") : $t("all.non") }}
          </template>
          <template slot="parent" slot-scope="text, record">
            {{ record.parent.fatherName }} {{ $t("all.and") }}
            {{ record.parent.motherName }}
          </template>
          <template slot="phone" slot-scope="text, record">
            {{ record.parent.fatherPhone }}<br />
            {{ record.parent.motherPhone }}
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              :title="$t('all.sureToDelete')"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.supprimer")
                }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import writtenNumber from "written-number";
import JsonCsv from "vue-json-csv";
import moment from "moment";
import { generateChart } from "vue-chartjs";

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};

export default {
  components: {
    JsonCsv,
  },
  async created() {
    await this.fetchData({ page: 1 });
    this.filtredTable = this.data;
  },
  computed: {
    ...mapState(["settings"]),
  },
  data() {
    return {
      isPdfModal: true,
      loadingCSV: false,
      filters: {},
      pagination: {},
      loadingPdf: false,
      mF: true,
      selectedMonth: "all",
      moment,
      tableLoading: true,
      rowData: [],
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      value: 1,
      visibleModal: false,
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "parent.clientName",
          key: "parent.clientName",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "parent",
          },
          onFilter: (value, record) => {
            return record.parent.clientName
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.payeQues"),
          dataIndex: "status",
          key: "status",
          width: "150px",
          scopedSlots: {
            customRender: "status",
          },
        },
        {
          title: this.$t("recette.enfant"),
          dataIndex: "studentName",
          key: "studentName",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "studentName",
          },
          onFilter: (value, record) => {
            let studentName = record.studentName;
            return studentName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.frais"),
          dataIndex: "type",
          key: "type",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "type",
          },
          onFilter: (value, record) => {
            return record.type.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantTotal"),
          dataIndex: "money",
          key: "money",
          width: "300px",
          scopedSlots: {
            customRender: "money",
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    getMonthName,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    saveCSV(data, filename = "data.csv") {
      let str = `${Object.keys(data[0]).join(",")}\n`;
      data.forEach((row) => {
        str += `${Object.values(row).join(",")}\n`;
      });

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(str);
      anchor.target = "_blank";
      anchor.download = filename;
      anchor.click();
    },
    async generateCSV() {
      this.loadingCSV = true;
      this.closeModal();
      let data;
      await apiClient
        .get("/payment/chiffreAffaire", {
          params: { ...this.filters, month: this.selectedMonth },
        })
        .then((res) => {
          data = res.data.payments;
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });

      const csvdata = data.map((item) => {
        return {
          nomClient: `${item.parent.fatherName} ${item.parent.motherName}`,
          Payé: item.status == "payed" ? "oui" : "non",
          enfant: item.studentName,
          Frais: item.type,
          montantTotale: item.money,
          montantPayé: item.totalPayed,
          montantNonPaye: item.totalUnpayed,
        };
      });

      this.saveCSV(csvdata, "Chiffre d'affaire.csv");
      this.loadingCSV = false;
      this.selectedMonth = "all";
    },
    async generatePdf() {
      this.closeModal();
      this.loadingPdf = true;
      this.$gtag.event("Imp Chiffre d'affaires", {
        event_category: "Impression PDF",
        event_label: "declarations:Chiffre d'affaires",
        value: 1,
      });

      let data = [];

      this.$notification.open({
        message: "Genration du PDF",
        description: "Le PDF est en cours de génération, veuillez patienter...",
        icon: <a-icon type="file-pdf" style="color: #108ee9" />,
        onClick: () => {},
      });

      await apiClient
        .get("/payment/chiffreAffaire", {
          params: { ...this.filters, month: this.selectedMonth },
        })
        .then((res) => {
          data = res.data.payments;
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });

      let selectMonth = document.getElementById("selectMonth").innerText;
      let arrayOfImages = this.settings.image;
      for (let i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      const date = new Date();

      const titre = "Chiffre d'affaire";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setLanguage("ar");
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.text(92, 70, selectMonth);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (error) {
        console.log(error);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      let arr = [];
      const array = [];
      let somme = 0;

      for (let i = 0; i < data.length; i++) {
        let currentIndex = 0;
        let monthsUnpaied = data[i].dataType.inscription ? 1 : 0;

        if (data[i].months) {
          for (let _ of Object.keys(data[i].months)) {
            monthsUnpaied++;
          }
        }
        if (currentIndex === 0) {
          currentIndex++;
          arr.unshift({
            rowSpan: monthsUnpaied,
            content: data[i].studentName,
            styles: { valign: "middle" },
          });
          arr.unshift({
            rowSpan: monthsUnpaied,
            content: data[i].parent.fatherPhone,
            styles: { valign: "middle" },
          });
          arr.unshift({
            rowSpan: monthsUnpaied,
            content: data[i].parent.fatherName,
            styles: { valign: "middle" },
          });
        }
        if (!currentIndex) {
          arr.push(data[i].parent.father.fatherName);
          arr.push(data[i].parent.fatherPhone);
          arr.push(data[i].studentName);
        }
        if (data[i].dataType.inscription) {
          arr.push("Inscription " + data[i].name);

          if (data[i].inscription == true) {
            arr.push({
              content: "payé",
              styles: { textColor: [14, 115, 31], fontStyle: "bold" },
            });
            arr.push(data[i].monthsMoney.inscription);
          } else {
            arr.push({
              content: "impayé",
              styles: { textColor: [209, 23, 45], fontStyle: "bold" },
            });
            arr.push(data[i].monthsMoney.inscription);
          }

          array.push(arr);
          arr = [];
        }
        if (data[i].months) {
          for (var key of Object.keys(data[i].months)) {
            let paymentDate = new Date(key);
            let monthName = paymentDate.toLocaleString("fr-FR", {
              month: "long",
            });
            arr.push(monthName + " - " + data[i].name);

            if (data[i].months[key] == true) {
              arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              });
              arr.push(data[i].monthsMoney[key]);
            } else {
              arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
              arr.push(
                data[i].monthsMoney[key] // * (1 - data[i].defaultReduction / 100)
              );
            }

            //colspan
            array.push(arr);
            arr = [];
          }
        }
        somme += parseFloat(data[i].money);
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          ["Nom client", "Téléphone", "Enfant", "Frais", "Etat", "Montant"],
        ],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 4,
              content: writtenNumber(somme, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            parseFloat(somme).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering

      this.loadingPdf = false;
      this.selectedMonth = "all";

      doc.save("Chiffre d'affaire.pdf");
    },

    handleChangeFilter(filter) {
      this.selectedMonth = filter;
    },

    async fetchData(params = {}) {
      this.tableLoading = true;
      return new Promise((resolve, reject) => {
        apiClient
          .get("/payment/chiffreAffaire", { params: params })
          .then((res) => {
            this.data = res.data.payments;
            this.pagination = {
              ...this.pagination,
              total: res.data.info.count,
            };
            this.rowData = [...this.data];
            this.tableLoading = false;
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    handleTableChange(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
      this.filters = filters;
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetchData({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    closeModal() {
      this.visibleModal = false;
    },
  },
};
</script>
